<template>
  <div id="mainPage">
    <div class="wallpaper"></div>
    <div id="custom-nav">
      <b-navbar toggleable="xl" type="dark" class="custom-nav-bar" fixed="top">
        <b-navbar-brand href="#">
          <img src="../assets/MathijoLogo.png" alt="Mathijo" width="100px" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto mr-auto custom-nav-bar-items">
            <b-nav-item href="#aboutUsNav">&Uuml;ber uns</b-nav-item>
            <b-nav-item href="#openingHoursNav">&Ouml;ffnungszeiten</b-nav-item>
            <b-nav-item href="#foodCardNav">Speisekarte</b-nav-item>
            <b-nav-item href="#reservationNav">Reservierung</b-nav-item>
            <b-nav-item href="#galerieNav">Galerie</b-nav-item>
            <b-nav-item href="#contactNav">Kontakt</b-nav-item>
          </b-navbar-nav>

          <b-navbar-brand
            class="social-media-icons"
            title="Facebook"
            href="https://www.facebook.com/p/Mathijo-100031055726426/"
          >
            <b-icon icon="facebook"></b-icon>
          </b-navbar-brand>
          <b-navbar-brand
            class="social-media-icons"
            title="Instagram"
            href="https://www.instagram.com/mathijo_saarburg/?hl=de"
          >
            <b-icon icon="instagram"></b-icon>
          </b-navbar-brand>
          <b-navbar-brand
            class="social-media-icons"
            title="Trip Advisor"
            href="https://www.tripadvisor.de/Restaurant_Review-g1074292-d21000744-Reviews-Mathijo-Saarburg_Rhineland_Palatinate.html"
          >
            <img
              src="../assets/TripAdvisorIcon.png"
              alt="Trip Advisor"
              class="trip-advisor-icon"
            />
          </b-navbar-brand>
        </b-collapse>
      </b-navbar>
    </div>

    <div id="headline">
      <h1 class="mathijoHeadline1">Mathijo</h1>
      <h2 class="mathijoHeadline2">Bistro | Weinbar | Caf&eacute;</h2>
      <div class="text-center mt-5">
        <b-button class="mr-3" variant="dark" @click="openFoodCard"
          >Speisekarte</b-button
        >
        <b-button class="reserveBtn" href="#reservationNav"
          >Reservierung</b-button
        >
      </div>
    </div>
    <div id="section">
      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="aboutUsNav"
      ></a>
      <div id="aboutUs" class="sections">
        <div class="section-headers">
          <h2>&Uuml;ber uns</h2>
          <hr style="background-color: white; width: 50%" />
        </div>
        <div id="first-paragraph">
          <p>
            Willkommen bei Mathijo - Ihrer gem&uuml;tlichen Weinbar und Bistro!
            <br />
            <br />
            Inmitten der Hektik des Alltags laden wir Sie ein, einen Moment der
            Entspannung in unserer charmanten Weinbar zu genie&szlig;en. Mathijo
            ist der Ort, an dem Genuss, Geselligkeit und eine besondere
            Atmosph&auml;re auf Sie warten. Tauchen Sie ein in unsere Welt des
            exquisiten Weines und delikater Kleinigkeiten, die Ihre Sinne
            verw&ouml;hnen.
            <br />
            <br />
            Unsere Weinliste ist sorgf&auml;ltig ausgew&auml;hlt und bietet eine
            breite Palette an Weinen, die von renommierten Weing&uuml;tern
            stammen. Ob Sie ein Kenner oder Neuling in der Welt des Weines sind,
            wir haben f&uuml;r jeden Geschmack und Anlass etwas Besonderes im
            Angebot.
            <br />
            <br />
            Neben unseren erlesenen Weinen bieten wir auch eine Auswahl an
            erfrischenden Cocktails, Bieren und alkoholfreien Getr&auml;nken an.
            F&uuml;r den kleinen Hunger empfehlen wir unsere k&ouml;stlichen
            Wurst- und K&auml;seplatten, die perfekt zu einem Glas Wein passen.
            Unsere Speisen werden mit Liebe zubereitet und sind eine wahre
            Gaumenfreude.
          </p>
          <img
            src="../assets/Mathijo3.jpg"
            alt="MathijoAussen"
            style="width: 100%; max-width: 400px; margin: 2rem"
          />
        </div>
        <div id="second-paragraph">
          <img
            src="../assets/Mathijo1.png"
            alt="MathijoInnen"
            style="width: 100%; max-width: 500px; margin: 2rem"
          />
          <p>
            Die Atmosph&auml;re bei Mathijo ist gepr&auml;gt von
            Gem&uuml;tlichkeit und Eleganz. Unser charmantes Ambiente l&auml;dt
            dazu ein, sich mit Freunden und Familie zu treffen oder einfach
            alleine zu entspannen. Wir legen gro&szlig;en Wert darauf, dass sich
            unsere G&auml;ste bei uns wie zu Hause f&uuml;hlen und in einer
            ruhigen, netten Atmosph&auml;re eine Auszeit vom Alltag
            genie&szlig;en k&ouml;nnen.
            <br />
            <br />
            Unser engagiertes Team freut sich darauf, Sie bei Mathijo
            begr&uuml;&szlig;en zu d&uuml;rfen und Ihnen ein unvergessliches
            Erlebnis zu bieten. Ob Sie nach einem Ort suchen, um den Tag
            ausklingen zu lassen, ein romantisches Date planen oder eine
            gesellige Feier veranstalten m&ouml;chten, Mathijo ist die perfekte
            Wahl.
            <br />
            <br />
            Entdecken Sie die Welt des guten Geschmacks bei Mathijo - Ihre
            Weinbar und Bistro in herzlicher Atmosph&auml;re. Wir laden Sie ein,
            unsere Leidenschaft f&uuml;r Wein und Genuss mit uns zu teilen.
            <br />
            <br />
            Besuchen Sie uns bald und lassen Sie sich von Mathijo verzaubern!
          </p>
        </div>
      </div>

      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="openingHoursNav"
      ></a>
      <div id="openingHours" class="sections">
        <div class="section-headers">
          <h2>&Ouml;ffnungszeiten</h2>
          <hr style="background-color: white; width: 50%" />
        </div>
        <!-- <p>Mittwoch - Freitag: ab 18 Uhr geöffnet</p>
        <p>Alle anderen Tage: Geschlossen</p> -->
        <p>Wir haben vorübergehend geschlossen.</p>
        <!-- <b-button id="popover-target" variant="outline-secondary">
          <b-icon icon="info-circle-fill"></b-icon>
        </b-button> -->

        <!-- <b-popover target="popover-target" triggers="hover" placement="top">
          <template #title>Auch geschlossen an folgenden Tagen:</template>
          06.11.2024 - 08.11.2024
          <br />
          27.11.2024 - 29.11.2024
        </b-popover> -->
      </div>

      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="foodCardNav"
      ></a>
      <div id="foodCard" class="sections">
        <div class="section-headers">
          <h2>Speisekarte</h2>
          <hr style="background-color: white; width: 50%" />
        </div>
        <b-button class="mr-3" variant="dark" @click="openFoodCard"
          >Speisekarte<b-icon class="ml-2" icon="box-arrow-up-right"></b-icon
        ></b-button>
      </div>

      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="reservationNav"
      ></a>
      <div id="reservation" class="sections">
        <div class="section-headers">
          <b-container id="reservationContainer" style="text-align: center">
            <h2 class="mb-2">Reservierung</h2>
            <hr class="mb-4" style="background-color: white; width: 50%" />
            <b-row class="mb-4">
              <b-col cols="4" class="d-flex align-items-center">
                <label>E-Mail:</label>
              </b-col>
              <b-col id="reservationMailInput">
                <b-form-input
                  v-model="mailAddress"
                  type="email"
                  placeholder="Email eingeben"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4" class="d-flex align-items-center">
                <label>Datum:</label>
              </b-col>
              <b-col id="reservationDatePicker">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="reservationDate"
                  :dark="true"
                  :min="minDate"
                  label-help=""
                  :date-disabled-fn="disabledDates"
                  label-no-date-selected="Datum wählen"
                ></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <!-- <b-col
                >Wenn das Datum nicht auswählbar ist, sind wir im Urlaub bzw.
                haben geschlossen.</b-col
              > -->
              <b-col
                >Wir haben vorübergehend geschlossen. Deshalb sind keine
                Reservierungen möglich.</b-col
              >
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4" class="d-flex align-items-center">
                <label>Uhrzeit:</label>
              </b-col>
              <b-col id="reservationTimePicker">
                <b-form-timepicker
                  v-model="reservationTime"
                  locale="de"
                  minutes-step="15"
                  :state="allowedTimes"
                ></b-form-timepicker>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4" class="d-flex align-items-center">
                <label>Personen:</label>
              </b-col>
              <b-col id="reservationAmountPicker">
                <b-form-input
                  type="number"
                  v-model="reservationAmount"
                ></b-form-input>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4" class="d-flex align-items-center">
                <label>Anmerkung:</label>
              </b-col>
              <b-col id="reservationNotesTextArea">
                <b-form-textarea
                  v-model="reservationNotes"
                  placeholder="..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="!disableReserveBtn">
                <b-button disabled variant="success" @click="reserve"
                  >Reservieren</b-button
                >
              </b-col>
              <b-col v-else>
                <b-button variant="success" disabled
                  ><b-spinner small></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="galerieNav"
      ></a>
      <div id="galerie" class="sections">
        <div class="section-headers">
          <h2>Galerie</h2>
          <hr style="background-color: white; width: 50%" />
          <div class="mt-4">
            <b-carousel
              style="margin: 0 auto"
              controls
              id="carousel-fade"
              fade
              indicators
            >
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo1.png"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo2.webp"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo3.jpg"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo4.jpg"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo5.webp"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo6.webp"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo7.webp"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo8.jpg"
                  class="galerieImages"
                />
              </b-carousel-slide>
              <b-carousel-slide>
                <img
                  slot="img"
                  src="../assets/Mathijo9.webp"
                  class="galerieImages"
                />
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>

      <a
        style="
          display: block;
          position: relative;
          top: -100px;
          visibility: hidden;
        "
        id="contactNav"
      ></a>
      <div id="contact" class="sections">
        <div class="section-headers">
          <h2>Kontakt</h2>
          <hr style="background-color: white; width: 50%" />
          <p>Mathijo</p>
          <p>Graf-Siegfried-Straße 28A</p>
          <p>54439 Saarburg</p>
          <p>Telefon: 06851/9988910</p>
          <p>Mail: info@mathijo.de</p>
        </div>
      </div>
      <div id="footer">
        <hr style="background-color: white; width: 90%; height: 3px" />
        <p>Developed by Thibaut Abendschein | Hosted by Firebase</p>
        <router-link to="/Impressum">Impressum / Datenschutz</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      mailAddress: "",
      reservationDate: null,
      reservationTime: "18:00",
      reservationAmount: 2,
      reservationNotes: "",
      minDate: new Date(),
      modalErrorConfig: {
        bodyBgVariant: "danger",
        footerBgVariant: "danger",
        bodyTextVariant: "light",
        okVariant: "dark",
        centered: true,
      },
      modalSuccessConfig: {
        bodyBgVariant: "success",
        footerBgVariant: "success",
        bodyTextVariant: "light",
        okVariant: "dark",
        centered: true,
      },
      disableReserveBtn: false,
    };
  },
  computed: {
    allowedTimes() {
      if (this.reservationTime) {
        let hour = parseInt(this.reservationTime.split(":")[0]);
        let minute = parseInt(this.reservationTime.split(":")[1]);
        if (hour < 18 || hour > 21 || (hour === 21 && minute > 0)) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    this.disableReserveBtn = false;
    if (dayOfWeek >= 3 && dayOfWeek <= 5) {
      this.reservationDate = today;
      if (this.currentlyClosed()) {
        this.reservationDate = null;
      }
    } else {
      this.reservationDate = null;
    }
  },
  methods: {
    currentlyClosed() {
      if (this.reservationDate) {
        console.log(this.reservationDate);
        const inputDate = new Date(this.reservationDate);

        const day = inputDate.getDate();
        const month = inputDate.getMonth();

        const specificDates = [
          { day: 10, month: 9 }, //(month is 0-indexed, so e.g. 8 is September)
          { day: 6, month: 10 },
          { day: 7, month: 10 },
          { day: 8, month: 10 },
          { day: 27, month: 10 },
          { day: 28, month: 10 },
          { day: 29, month: 10 },
        ];
        let isCurrentlyClosed = specificDates.some(
          (d) => d.day === day && d.month === month
        );
        console.log(isCurrentlyClosed);
        return isCurrentlyClosed;
      }
    },
    openFoodCard() {
      window.open("https://mathijo.de/Speisekarte.pdf", "_blank");
    },
    disabledDates(ymd, date) {
      const weekday = date.getDay();

      // Disable specific dates
      if (
        ymd === "2024-10-10" ||
        ymd === "2024-11-06" ||
        ymd === "2024-11-07" ||
        ymd === "2024-11-08" ||
        ymd === "2024-11-27" ||
        ymd === "2024-11-28" ||
        ymd === "2024-11-29"
      ) {
        return true;
      }

      // Disable specific days of the week
      if (weekday === 0 || weekday === 1 || weekday === 2 || weekday === 6) {
        return true;
      }

      return false;
    },
    reserve() {
      this.disableReserveBtn = true;
      if (
        !this.mailAddress ||
        !this.reservationDate ||
        !this.reservationTime ||
        !this.reservationAmount
      ) {
        this.$bvModal.msgBoxOk(
          "Für die Reservierung benötigen wir Ihre Mail-Adresse, das Datum, die Uhrzeit und die Anzahl der Personen. Bitte geben Sie in allen Feldern einen korrekten Wert an.",
          this.modalErrorConfig
        );
        this.disableReserveBtn = false;
        return;
      }

      if (!this.allowedTimes) {
        this.$bvModal.msgBoxOk(
          "Reservierungen sind nur zwischen 18 und 21 Uhr möglich.",
          this.modalErrorConfig
        );
        this.disableReserveBtn = false;
        return;
      }

      this.reservationDate = new Date(this.reservationDate);

      const mailData = {
        subject: "Neue Reservierung Mathijo",
        html: `<h2>Eine neue Reservierung für Mathijo ist eingetroffen!</h2>
        <br />
        <b>Mail:</b> ${this.mailAddress}
        <br />
        <br />
        <b>Datum:</b> ${this.reservationDate.toLocaleDateString()}
        <br />
        <b>Uhrzeit:</b> ${this.reservationTime}
        <br />
        <br />
        <b>Anzahl Personen:</b> ${this.reservationAmount}
        <br />
        <br />
        <b>Anmerkungen:</b> ${this.reservationNotes}
        <br />
        <br />
        <br />
        <a style="display: inline-block; background-color: #28a745; color: #ffffff; text-decoration: none; padding: 10px 20px; border-radius: 5px; margin-right: 20px; margin-top: 20px;" href="mailto:${
          this.mailAddress
        }?subject=Mathijo - Reservierungsbestätigung&body=Hallo!%0D%0A%0D%0AHiermit bestätigen wir Ihre Reservierung für den ${this.reservationDate.toLocaleDateString()} um ${
          this.reservationTime
        } Uhr für ${
          this.reservationAmount
        } Personen.%0D%0A%0D%0A%0D%0AMit freundlichen Grüßen,%0D%0A%0D%0AIhr Mathijo Team">Automatische Zusage</a>
        <a style="display: inline-block; background-color: #dc3545; color: #ffffff; text-decoration: none; padding: 10px 20px; border-radius: 5px; margin-top: 20px;" href="mailto:${
          this.mailAddress
        }?subject=Mathijo - Reservierungsabsage&body=Hallo!%0D%0A%0D%0AWir können leider für den ${this.reservationDate.toLocaleDateString()} um ${
          this.reservationTime
        } Uhr für ${
          this.reservationAmount
        } Personen keinen Tisch anbieten. Das tut uns sehr leid.%0D%0A%0D%0A%0D%0AMit freundlichen Grüßen,%0D%0A%0D%0AIhr Mathijo Team">Automatische Absage</a>
        <br />
        <br />
        <br />
        Automatisiert gesendet von Mathijo-Website.
        `,
      };

      //Test-URL: "http://localhost:5001/mathijo-13e56/us-central1/sendEmailHttps"
      //Prod-URL: "https://us-central1-mathijo-13e56.cloudfunctions.net/sendEmailHttps"

      fetch(
        "https://us-central1-mathijo-13e56.cloudfunctions.net/sendEmailHttps",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mailData),
        }
      )
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$bvModal.msgBoxOk(
              "Vielen Dank für Ihre Reservierung! Sie erhalten in Kürze eine Bestätigung per Mail.",
              this.modalSuccessConfig
            );
          } else {
            this.$bvModal.msgBoxOk(
              "Es tut uns leid, unser Reservierungssystem ist aktuell defekt. Wir kümmern uns zeitnah darum. Rufen Sie in der Zwischenzeit bei uns an!",
              this.modalErrorConfig
            );
          }
          this.disableReserveBtn = false;
        })
        .catch((error) => {
          console.log(error);
          this.$bvModal.msgBoxOk(
            "Es tut uns leid, unser Reservierungssystem ist aktuell defekt. Wir kümmern uns zeitnah darum. Rufen Sie in der Zwischenzeit bei uns an!",
            this.modalErrorConfig
          );
          this.disableReserveBtn = false;
        });
    },
  },
};
</script>

<style>
#mainPage {
}

.wallpaper {
  background: url(../assets/MathijoBlurred.png) no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.custom-nav-bar {
  z-index: 2;
  text-wrap: nowrap;
  background-color: rgba(0, 0, 0, 0.9);
}

.custom-nav-bar-items {
  font-size: 1.3rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
  font-family: "Comic Sans MS";
}

.navbar-dark .navbar-nav .nav-link:hover {
  opacity: 0.5;
}

.nav-item {
  margin: 15px;
}

#headline {
  position: absolute;
  top: 35vh;
  left: 0;
  right: 0;
}

.mathijoHeadline1 {
  text-align: center;
  color: white;
  font-family: "Comic Sans MS";
  font-size: calc(15px + 3.5vw) !important;
}

.mathijoHeadline2 {
  text-align: center;
  color: white;
  font-family: "Comic Sans MS";
  font-size: calc(15px + 1.5vw) !important;
}

.reserveBtn {
  background-color: brown !important;
  border: 1px solid brown !important;
}

.reserveBtn:hover {
  background-color: darkred !important;
  border: 1px solid darkred !important;
}

.trip-advisor-icon {
  width: 40px;
  margin-left: -6px;
}

.social-media-icons {
  cursor: pointer;
}

#section {
  position: absolute;
  top: 100vh;
}

.sections {
  color: white;
  max-width: 100%;
  text-align: center;
  margin: 5vh auto;
  font-family: "Comic Sans MS" !important;
}

.section-headers {
  margin-bottom: 30px;
}

#first-paragraph {
  display: flex;
  align-items: center;
  text-align: start;
}

#second-paragraph {
  display: flex;
  align-items: center;
  text-align: end;
}

@media screen and (max-width: 1600px) {
  .galerieImages {
    width: 100%;
  }
  #first-paragraph {
    flex-direction: column;
    text-align: center !important;
    margin: 10px;
  }

  #second-paragraph {
    flex-direction: column;
    text-align: center !important;
    margin: 10px;
  }
}

@media screen and (min-width: 768px) {
  .sections {
    max-width: 60%;
  }

  #reservationMailInput {
    max-width: 400px;
  }
  #reservationDatePicker {
    max-width: 300px;
  }

  #reservationTimePicker {
    max-width: 200px;
  }

  #reservationAmountPicker {
    max-width: 100px;
  }

  #reservationNotesTextArea {
    max-width: 400px;
  }

  #reservationContainer {
    max-width: 700px;
  }

  #reservationContainer {
    border: 2px solid grey;
    border-radius: 10px;
    padding: 1.5rem;
    background-color: rgba(48, 48, 48, 0.4);
  }
}

#footer {
  text-align: center;
  color: white;
  margin-bottom: 15px;
  font-family: "Comic Sans MS" !important;
}
</style>
